
import swal from 'sweetalert2'
import Rails from '@rails/ujs'

Rails.confirm = function (message, element) {
	const swalWithBootstrap = swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success-light ms-4 mt-1',
			cancelButton: 'btn btn-danger-light mt-1'
		},
		buttonsStyling: false
	})

	swalWithBootstrap.fire({
		html: message,
		showCancelButton: true,
		confirmButtonText: 'Aceptar',
		cancelButtonText: 'Cancelar',
		reverseButtons: true,
		focusConfirm: false
	})
		.then((result) => {
			if (result.value) {
				element.removeAttribute('data-confirm')
				element.click()
			}
		})
}