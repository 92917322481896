import "select2"
import "select2/dist/css/select2.css"
import "select2-bootstrap-theme/dist/select2-bootstrap.css"

document.addEventListener("turbolinks:load", () => {
	$('.select2, #selectRegion').select2({
		theme: 'bootstrap',
		"language": {
			"noResults": function () {
				return "No se encontraron resultados, verifique";
			}
		}
	});

	function productsFlavorTemplate(product) {
		if (!product.id) {
			return product.text;
		}

		var productWithImage = $(
			`<div class="d-flex">
				<div class="d-flex align-items-center flex-grow-1"> ${product.text} </div>
				<img src="${product.element.dataset.image}" class="img-product-flavor me-1 me-md-2"/>
			</div>`
		);

		return productWithImage;
	};

	function updateProductFlavorImgAndUrlForm() {
		let optionSelected = $("#siblingsProducts option:selected")[0]
		let productImgSelected = optionSelected.dataset.image
		let productSlugSelected = optionSelected.dataset.slug
		let newQuantityForSelect = Number(optionSelected.dataset.quantity)

		// change url with new product slug
		window.history.pushState({ turbolinks: {} }, "", optionSelected.dataset.slug);

		// replace original name for name option selected
		const originalProductName = document.querySelectorAll('.originalProductName')
		originalProductName.forEach(originalProduct => {
			originalProduct.innerHTML = optionSelected.innerHTML
		});

		// replace price && price 3 monthly payments
		const newPrice = optionSelected.dataset.price
		let currentPrice = document.querySelector('.price')
		currentPrice.innerHTML = newPrice

		const newMonthlyPayments = optionSelected.dataset.priceMonthlyPayment
		let currentMonthlyPayments = document.getElementById('priceThreeMonthlyPayments')
		currentMonthlyPayments.innerHTML = newMonthlyPayments

		// replace carousel img for product img selected
		let originalImg = document.querySelector('.carousel-item.active').querySelector('img')
		originalImg.src = productImgSelected
		originalImg.style = 'width: 230px !important;'

		// replace img in zoomContainer (ez-plus)
		let zoomWindow = document.querySelector('.zoomWindow')
		zoomWindow.style.backgroundImage = `url(${productImgSelected})`

		// replace carousel mini img for product img selected
		let carouselProduct = document.querySelector("[data-bs-slide-to='0']")
		if (carouselProduct) {
			carouselProduct.querySelector('img').src = productImgSelected
		}

		// replace name and img for productFixedName
		const productFixedName = document.getElementById('productFixedName')
		productFixedName.innerHTML = optionSelected.innerHTML
		productFixedImg.src = productImgSelected

		// change url for formAddProductToCart
		const formAddProductToCart = document.querySelectorAll('.formAddProductToCart')
		formAddProductToCart.forEach(form => {
			// get current form and change url (this because 2 forms exists, the principal and the other when scrolling down)
			form.action = `/add_product_to_cart/${productSlugSelected}`

			// get current select && reset options
			const selectForProduct = form.querySelector('.selectForProduct')
			selectForProduct.options.length = 0;

			// add new options until newQuantityForSelect
			for (let i = 1; i <= newQuantityForSelect; i++) {
				let option = document.createElement("option");
				option.value = option.innerHTML = i
				selectForProduct.appendChild(option);
			}
		});
	}

	$("#siblingsProducts").select2({
		templateResult: productsFlavorTemplate,
		templateSelection: productsFlavorTemplate,
		minimumResultsForSearch: -1
	});

	$('#siblingsProducts').on('change', function (event) {
		updateProductFlavorImgAndUrlForm()
	})
});

// Issues with turbolinks before-cache: remove select2 && bootstrap modal backdrop to initialize again
$(document).on("turbolinks:before-cache", function () {
	if ($('.select2').length) {
		$('#selectRegion').select2('destroy');
		$('#selectCommune').select2('destroy');
		$('#siblingsProducts').select2('destroy');
	}

	// delete shade modal bootstrap Suggestive products
	// TODO: Falta arreglar que cuando voy hacia atrás el modal del checkout no cierra (dismiss modal)
	if ($('#modalSuggestiveProducts').length) {
		$('.modal-backdrop').remove();
	}
});