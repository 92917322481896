import { currencyNumber, cursorFocus } from './functions.js'

document.addEventListener("turbolinks:load", () => {

	// change step on click
	const step1 = document.getElementById('step-1')
	const step2 = document.getElementById('step-2')
	const buttonNextStep = document.getElementById('nextStep')
	const buttonBeforeStep = document.getElementById('beforeStep')

	if (buttonNextStep) {
		buttonNextStep.addEventListener("click", function () {
			step1.classList.replace('d-flex', 'd-none')
			step2.classList.replace('d-none', 'd-flex')
			window.history.pushState({ turbolinks: {} }, "", '?checkout=true')
			focusElement()
		})
	}

	if (buttonBeforeStep) {
		buttonBeforeStep.addEventListener("click", function () {
			step2.classList.replace('d-flex', 'd-none')
			step1.classList.replace('d-none', 'd-flex')
			window.history.pushState({ turbolinks: {} }, "", '/carro')
			let elementToFocus = document.querySelector('.delete-cart-product')
			cursorFocus(elementToFocus)
		})
	}

	function focusElement() {
		const orderElements = document.getElementsByClassName('focusOrderElement')

		if (orderElements) {
			for (let i = 0; i < orderElements.length; i++) {
				const element = orderElements[i];
				if (element.value == '') { return cursorFocus(element) }
			}
		}
	}

	const selectQuantityProducts = document.getElementsByClassName('changeQuantityProducts')

	for (let i = 0; i < selectQuantityProducts.length; i++) {
		const element = selectQuantityProducts[i];
		element.addEventListener("change", function () { updatePriceProduct(this) }, false)
	}

	// delete product AJAX
	deleteCartProduct()

	focusElement()

	var modalSuggestiveProducts = document.getElementById('modalSuggestiveProducts')

	if (modalSuggestiveProducts) {
		new bootstrap.Modal(modalSuggestiveProducts, {}).show()
	}
});

// update finalPrice with Chilexpress
export function ajaxFinalPriceWithChilexpress(price, deliveryCode) {
	price = Number(price)
	let url = `/price_with_chilexpress/?price=${price}&delivery_code=${deliveryCode}`

	$.ajax({
		method: 'PATCH',
		url: url,
		success: function (result) {
			const totalCartWithChilexpress = document.getElementsByClassName('totalCartWithChilexpress')
			const amountChilexpress = document.getElementById('amountChilexpress')
			const amountModalChilexpress = document.getElementById('amountModalChilexpress')

			let finalDeliveryPrice = result.delivery == 0 ? 'GRATIS 🔥' : currencyNumber(result.delivery)
			amountChilexpress.innerHTML = amountModalChilexpress.innerHTML = finalDeliveryPrice

			for (let i = 0; i < totalCartWithChilexpress.length; i++) {
				const element = totalCartWithChilexpress[i];
				element.innerHTML = currencyNumber(result.total_price_with_chilexpress)
			}
		}
	})
}