import 'bootstrap/dist/css/bootstrap.min.css';
import "stylesheets/application"

// For bootstrap v5
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

export { default as alert_message } from "./alert_message";
export { default as validators } from "./validators";

// add * when the file need to import/export libraries
export * from "./jquery";
export * from "./carts";
export * from "./functions";
export * from "./glide";
export * from "./inputs";
export * from "./hamburguer";
export * from "./loading";
export * from "./products";
export * from "./orders.js";
export * from "./polls";
export * from "./rut_jquery";
export * from "./select2";
export * from "./skeleton";
export * from "./sweetalert";
export * from "./pusher-notifications";
export * from "./user";