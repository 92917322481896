import { currencyNumber, loadingGif } from './functions.js'
import { ajaxFinalPriceWithChilexpress } from './carts.js'

document.addEventListener("turbolinks:load", () => {

	const totalCartWithChilexpress = document.getElementsByClassName('totalCartWithChilexpress')

	function resetDataSetTotalCartWithChilexpress() {
		for (let i = 0; i < totalCartWithChilexpress.length; i++) {
			const element = totalCartWithChilexpress[i];
			element.innerHTML = currencyNumber(element.dataset.originalPrice)
		}
	}
	// change regions by commune (Mix logic: used in pickAddressesForCurrentUser && selectRegion onchange)
	function changeRegion(fromPickUpAddresses = false, selectRegionId = null, selectCommuneId = null) {
		if (fromPickUpAddresses) {
			ajaxChangeRegion(fromPickUpAddresses, selectRegionId, selectCommuneId)
		} else {
			$('#selectRegion').on('change', function () {
				ajaxChangeRegion()
			})
		}
	}
	changeRegion()

	const amountChilexpress = document.getElementById('amountChilexpress')
	const selectCommune = document.getElementById('selectCommune')

	function ajaxChangeRegion(fromPickUpAddresses = false, id = $("#selectRegion option:selected")[0].value, selectCommuneId = null) {
		if (amountChilexpress) {
			amountChilexpress.innerHTML = 'Por calcular'
			resetDataSetTotalCartWithChilexpress()
		}

		if (id !== "") {

			// came from pickAddressesForCurrentUser (force change selectRegion value)
			if (fromPickUpAddresses) {
				$("#selectRegion").val(id).trigger('change.select2');
			}

			// remove bootstrap is-invalid class
			removeIsInvalidClassjQueryElements('#selectRegion')

			let url = `/get_regions.json?id=${id}`
			fetch(url)
				.then(function (response) {
					return response.json();
				})
				.then(function (result) {
					selectCommune.disabled = false
					selectCommune.innerHTML = ""
					selectCommune.appendChild(defaultOption()) // append default option

					result.forEach((data) => {
						let option = document.createElement("option")
						option.innerHTML = data.name
						option.dataset.countyCode = data.county_code_chilexpress
						option.value = data.id
						selectCommune.appendChild(option) // append all the options after defaultOption
					});

					$("#selectCommune").val(selectCommuneId).trigger('change.select2'); // came from pickAddressesForCurrentUser
				})
		} else { //reset select
			hideTotalMyOrder()
			selectCommune.disabled = true
			selectCommune.innerHTML = ""
			selectCommune.appendChild(defaultOption())
		}
		resetAddressAndNumberStreet()
	}

	// hide/show totalMyOrder
	const totalMyOrder = document.getElementsByClassName('total-my-order')

	function displayTotalMyOrder() {
		Array.from(totalMyOrder).forEach(myOrder => {
			if (myOrder.classList.contains('d-none')) {
				myOrder.classList.replace('d-none', 'table-row')
			}
		});
	}

	function hideTotalMyOrder() {
		Array.from(totalMyOrder).forEach(myOrder => {
			if (myOrder.classList.contains('table-row')) {
				myOrder.classList.replace('table-row', 'd-none')
			}
		});
	}

	// when Commune change -> send data to chilexpress for get price of delivery
	$('#selectCommune').on('change', function () {
		let optionSelected = $("#selectCommune option:selected")[0]
		if (optionSelected) {
			calculateDeliveryChilexpress(optionSelected)
		}
	})

	// when pickAddressesForCurrentUser change -> send data to chilexpress for get price of delivery
	const pickAddressesForCurrentUser = document.querySelectorAll('.pick-address')
	var hasClicked = false

	pickAddressesForCurrentUser.forEach(address => {
		address.addEventListener("click", function () {
			let inputSelected = this.querySelector('input')
			let dataset = inputSelected.dataset

			if (!hasClicked) { // force to invoke this functions only ONCE time
				calculateDeliveryChilexpress(inputSelected)
				changeRegion(true, dataset.regionId, dataset.communeId)
			}
			hasClicked = true

			// fill with data
			document.getElementById('address_street').value = dataset.street
			document.getElementById('address_street_number').value = dataset.streetNumber
			document.getElementById('address_street_room').value = dataset.room;

			removeClass('focusOrderElement', 'is-invalid')

			// scroll to "Tu pedido" div
			window.scroll({
				behavior: 'smooth',
				left: 0,
				top: document.querySelector('.pay-order').offsetTop
			});

			$('#modalCurrentUserAdressess').modal('hide')
		})
	});

	function calculateDeliveryChilexpress(optionSelected) {
		let destinationCountyCode = optionSelected.dataset.countyCode
		let subTotal = document.querySelector('.sub-total-cart')

		if (subTotal) { // esto solo para form orders (ya que tengo el mismo select en my-addresses)
			if (optionSelected.value !== '') {
				loadingGif('d-none', 'd-flex')

				// remove bootstrap is-invalid class
				removeIsInvalidClassjQueryElements('#selectCommune')

				fetch('/chilexpress/calculate_delivery', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json;charset=utf-8' },
					body: JSON.stringify({
						"chilexpress": {
							"destination_county_code": destinationCountyCode,
						}
					})
				})
					.then(function (response) {
						return response.json();
					})
					.then(function (result) {
						loadingGif('d-flex', 'd-none')

						if (result.statusCode == 0) {
							const courierServiceOptions = result.data.courierServiceOptions
							// ordeno por el menor precio y luego lo obtengo del primer arreglo
							const minOptionChilexpress = courierServiceOptions.sort((a, b) => parseFloat(a.serviceValue) - parseFloat(b.serviceValue));

							let price = minOptionChilexpress[0].serviceValue
							let deliveryCode = minOptionChilexpress[0].serviceTypeCode
							ajaxFinalPriceWithChilexpress(price, deliveryCode)
							displayTotalMyOrder()

						} else {
							amountChilexpress.innerHTML = 'Por calcular'
							hideTotalMyOrder()
						}
					})
			} else {
				hideTotalMyOrder()
				amountChilexpress.innerHTML = 'Por calcular'
			}
			resetAddressAndNumberStreet()
		}
	}

	function hasMinimumCharactersAndSelectRegionAndCommune(request) {
		var regionSelected = $("#selectRegion option:selected")[0]
		var communeSelected = $("#selectCommune option:selected")[0]

		// minimum 3 characters && regionSelected, communeSelected are not blank
		if (request.length >= 3 && regionSelected.value !== '' && communeSelected.value !== '') {
			return true
		} else {
			return false
		}
	}

	// autocomplete street with Chilexpress API
	$('#address_street').autocomplete({
		source: function (request, response) {
			var addressStreet = this.element

			if (request.term.trim() !== '') {
				addressStreet.addClass('loading-input')
			}

			if (hasMinimumCharactersAndSelectRegionAndCommune(request.term)) {
				let communeSelected = $("#selectCommune option:selected")[0].innerHTML
				let alertStreet = document.getElementById('alertStreet')

				$.ajax({
					url: '/chilexpress/search_address_streets',
					headers: { 'Content-Type': 'application/json;charset=utf-8' },
					method: 'POST',
					data: JSON.stringify({
						"chilexpress": {
							"county_name": communeSelected,
							"street_name": request.term.trim()
						}
					}),
					success: function (result) {
						addressStreet.removeClass('loading-input')

						if (result.statusCode == 0) { // add streets to select autocomplete
							alertStreet.classList.replace('d-block', 'd-none')
							addressStreet[0].setCustomValidity('');
							response($.map(result.streets, function (street) {
								return {
									label: street.streetName,
									id: street.streetId
								}
							}))
						} else {
							let errorMessage = `No encontramos coincidencias para la calle`
							addressStreet[0].setCustomValidity(`${errorMessage} ${request.term.toUpperCase()}, verifica`);

							// hide select autocomplete
							$(".ui-menu-item").hide();
							addressStreet.select()

							displayAlertWithTimeOut(alertStreet, errorMessage, request.term)
						}
					}
				})
			}
		},
		// set data-attribute: streetNameId in input when option is selected
		select: function (event, ui) {
			this.dataset.streetNameId = ui.item.id
		}
	});


	// autocomplete street number with Chilexpress API
	$('#address_street_number').autocomplete({
		source: function (request, response) {
			var addressStreetNumber = this.element

			if (request.term.trim() !== '') {
				addressStreetNumber.addClass('loading-input')

				const inputAddressStreet = document.getElementById('address_street')
				let streetNameId = inputAddressStreet.dataset.streetNameId

				if (streetNameId !== '') {
					let alertStreetNumber = document.getElementById('alertStreetNumber')

					$.ajax({
						url: '/chilexpress/search_address_streets_number',
						headers: { 'Content-Type': 'application/json;charset=utf-8' },
						data: {
							"chilexpress": {
								"street_name_id": streetNameId,
								"street_number": request.term.trim()
							}
						},
						error: function (e) {
							if (e.status == 400) {
								let errorMessage = `No encontramos coincidencias para el número de calle`
								addressStreetNumber[0].setCustomValidity(`${errorMessage} ${request.term.toUpperCase()}, verifica`);
								//
								$(".ui-menu-item").hide(); // hide select autocomplete
								addressStreetNumber.removeClass('loading-input')
								addressStreetNumber.select()
								//
								alertStreetNumber.classList.replace('d-none', 'd-block')
								alertStreetNumber.innerHTML = `${errorMessage} <span class="fw-500">${request.term.toUpperCase()}</span>, verifica`
							}
						},
						success: function (result) {
							addressStreetNumber[0].setCustomValidity('');
							addressStreetNumber.removeClass('loading-input')
							alertStreetNumber.classList.replace('d-block', 'd-none')
							//
							response($.map(result.streetNumbers, function (street) {
								return { label: street.number }
							}))
						}
					})
				}
			}
		}
	});

	// validate inputs: If true display modal otherwise show input
	const btnModalConfirmationOrder = document.getElementById('btnModalConfirmationOrder')

	if (btnModalConfirmationOrder) {
		btnModalConfirmationOrder.addEventListener("click", function () {
			removeBlankSpacesInputsOrderForm()
			hideAlertInputs()
			validateOrderForm()
		})
	}

	const orderForm = document.getElementById('orderForm')

	function validateOrderForm() {
		// display modalConfirmationOrder or validate inputs
		let requiredInputs = orderForm.querySelectorAll("[required]")

		if (orderForm.checkValidity()) {
			displayModalConfirmationOrder()
			$('#modalConfirmationOrder').modal('show');
			// remove bootstrap class is-invalid
			for (let i = 0; i < requiredInputs.length; i++) {
				const input = requiredInputs[i];
				if (input.classList.contains("is-invalid")) { input.classList.remove("is-invalid") }
			}

		} else {
			requiredInputs = [].slice.call(requiredInputs, 0).reverse() // hice esto porque valida los ultimos requiredInput antes que los primeros

			for (let i = 0; i < requiredInputs.length; i++) {
				const input = requiredInputs[i];
				if (!orderForm.checkValidity()) {
					input.reportValidity()
					input.value == '' ? input.classList.add("is-invalid") : input.classList.remove("is-invalid")
				}
			}
		}
	}

	// display modal ConfirmationOrder
	function displayModalConfirmationOrder() {
		const modalConfirmationFullName = document.getElementById('modalConfirmationFullName')
		const modalConfirmationEmail = document.getElementById('modalConfirmationEmail')
		const modalConfirmationRUT = document.getElementById('modalConfirmationRUT')
		const modalConfirmationPhone = document.getElementById('modalConfirmationPhone')
		const modalConfirmationAddress = document.getElementById('modalConfirmationAddress')
		const modalRowObservation = document.getElementById('rowObservation')
		const modalConfirmationObservation = document.getElementById('modalConfirmationObservation')
		//
		const formName = document.getElementById('order_customer_attributes_name') || document.getElementById('current_user_name')
		const formLastName = document.getElementById('order_customer_attributes_last_name') || document.getElementById('current_user_last_name')
		const formRUT = document.getElementById('order_customer_attributes_rut') || document.getElementById('current_user_rut')
		const formEmail = document.getElementById('order_customer_attributes_email') || document.getElementById('current_user_email')
		const formPhone = document.getElementById('order_customer_attributes_phone') || document.getElementById('current_user_phone')
		const formCommune = $("#selectCommune option:selected")[0].innerHTML
		const formStreet = document.getElementById('address_street').value
		const formStreetNumber = document.getElementById('address_street_number').value
		const formStreetRoom = document.getElementById('address_street_room').value
		const formOrderNotes = document.getElementById('order_notes').value

		// Dpto-Casa
		const room = formStreetRoom.trim() !== '' ? `Nº ${formStreetRoom},` : ''

		// Observaciones
		if (formOrderNotes !== '') {
			modalRowObservation.classList.replace('d-none', 'd-flex')
			modalConfirmationObservation.innerHTML = formOrderNotes
		} else {
			modalRowObservation.classList.replace('d-flex', 'd-none')
		}

		// Adds values from FORM to MODAL
		modalConfirmationFullName.innerHTML = `${formName.value} ${formLastName.value}`
		modalConfirmationRUT.innerHTML = formRUT.value
		modalConfirmationEmail.innerHTML = formEmail.value.toLowerCase()
		modalConfirmationPhone.innerHTML = `+56 ${formPhone.value}`
		modalConfirmationAddress.innerHTML = `${formStreet} ${formStreetNumber}, ${room} ${formCommune}`.toLowerCase()

	}

	const btnsubmitOrderForm = document.getElementById('btnSubmitOrderForm')

	if (btnsubmitOrderForm) {
		btnsubmitOrderForm.addEventListener("click", function () {
			// Hace submit onclick ya que existe un bug al enviar el form con TransbankLog en el controlador
			this.disabled = true
			this.innerHTML = `<span class="spinner-border spinner-border-sm"></span>
												<span class="ms-2"> CARGANDO </span>`
			orderForm.submit()
		})
	}


	// remove blank spaces for inputs in OrderForm
	function removeBlankSpacesInputsOrderForm() {
		var inputsOrderForm = orderForm.querySelectorAll("[required]")

		for (let i = 0; i < inputsOrderForm.length; i++) {
			const input = inputsOrderForm[i];
			input.value = input.value.trim()
		}
	}

	// Abre (selectRegion || selectCommune) si estan vacíos y está escribiendo en input 'Calle'
	function openSelectRegionOrSelectCommune() {
		const inputAddressStreet = document.getElementById('address_street')
		const inputAddressStreetNumber = document.getElementById('address_street_number')
		const selectRegion = $('#selectRegion')
		const selectCommune = $('#selectCommune')

		if (inputAddressStreet) {
			inputAddressStreet.addEventListener("input", function () {
				if (selectRegion[0].value == '') {
					this.value = ''
					selectRegion.select2('open')
				} else if (selectRegion[0].value !== '' && selectCommune[0].value == '') {
					selectCommune.select2('open')
					this.value = ''
				}
			})

			// inputAddressStreetNumber: focus in inputStreet if is blank 
			inputAddressStreetNumber.addEventListener("input", function () {
				if (inputAddressStreet.value == '') {
					this.value = ''
					inputAddressStreet.focus()
				}
			})

		}
	}

	openSelectRegionOrSelectCommune()


	// Remove loadingGif if contains in input
	const loadingGifInputs = document.getElementsByClassName('loadingGifInput')
	if (loadingGifInputs) {
		for (let i = 0; i < loadingGifInputs.length; i++) {
			const input = loadingGifInputs[i];

			input.addEventListener("change", function () {
				if (input.classList.contains('loading-input')) {
					input.classList.remove('loading-input')
				}
			})
		}
	}

	// Remove bootstrap is-invalid class for inputs on change
	function removeIsInvalidClassjQueryElements(id) {
		if ($(`${id}`).hasClass('is-invalid')) {
			$(`${id}`).removeClass('is-invalid')
		}
	}

	function removeClass(classElements, className, onChange = false) {
		const elements = document.getElementsByClassName(classElements)

		if (elements) {
			for (let i = 0; i < elements.length; i++) {
				const input = elements[i];

				if (onChange) {
					input.addEventListener("change", function () {
						removeInputClass(input, className)
					})
				} else {
					removeInputClass(input, className)
				}
			}
		}
	}

	function removeInputClass(input, className) {
		if (input.value !== '' && input.classList.contains(className)) {
			input.classList.remove(className)
		}
	}

	removeClass('focusOrderElement', 'is-invalid', true)

	// hide alerts inputs
	function hideAlertInputs() {
		const alerts = document.getElementsByClassName('alert-danger')
		for (let i = 0; i < alerts.length; i++) {
			const alert = alerts[i];
			if (alert.classList.contains('d-block')) {
				alert.classList.replace('d-block', 'd-none')
			}
		}
	}

});