// Glide: Es6 carousel slider 

import Glide from '@glidejs/glide'
import '@glidejs/glide/src/assets/sass/glide.core';
import '@glidejs/glide/src/assets/sass/glide.theme';

document.addEventListener("turbolinks:load", () => {

	var sliders = document.querySelectorAll('.glide');

	for (var i = 0; i < sliders.length; i++) {
		const glide = sliders[i]
		new Glide(glide, {
			type: 'carousel',
			animationDuration: 300,
			animationTimingFunc: 'linear',
			perView: 4,
			breakpoints: { //responsive
				600: {
					perView: glide.dataset.quantityItemsResponsive // data-attribute
				},
				1024: {
					perView: 3
				},
				2450: {
					perView: 4
				}
			},
			peek: { // distance to next element
				before: 5,
				after: glide.dataset.peekAfter // data-attribute (data-peek-after)
			},
			gap: 10, // separation between elements
		}).mount()

	}

})
