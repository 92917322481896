// Global functions for use in all the project

document.addEventListener("turbolinks:load", () => {

	let btnScroll = document.getElementById('buttonScrollTop');
	const divProduct = document.querySelector('.product')
	let addProductFixed = document.querySelector('.add-product-fixed')

	window.addEventListener('scroll', function () {
		// display btnScroll
		window.scrollY > 300 ? btnScroll.classList.add('show') : btnScroll.classList.remove('show')

		// display btnScroll with addProductFixed 
		if (addProductFixed) {
			btnScroll.classList.add('with-product-fixed')
			window.scrollY > divProduct.clientHeight ? addProductFixed.classList.add('show') : addProductFixed.classList.remove('show')
		}
	});

	// btnScroll onClick: go to the top of window
	btnScroll.addEventListener('click', function (e) {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, false)

	// form on submit && ajax:success
	loadSpinnerOnAjaxFormSubmit()
})

export function loadingGif(firstClass, secondClass) {
	let divLoadingGif = document.getElementById('loading')
	divLoadingGif.classList.replace(firstClass, secondClass)
}

export function cursorFocus(elem) {
	var x = window.scrollX, y = window.scrollY
	window.scrollTo(x, y)
	elem.focus()
}

// set currency number
export function currencyNumber(num) {
	num = Number(num).toLocaleString('en-US').replace(',', '.')
	return `$${num}`
}

// convert currency string to number
export function convertCurrencyToNumber(currency) {
	return Number(currency.replace(/\$/g, "").replace(/\./g, ""))
}

// set multiple attributes for element
export function setAttributes(elem, attrs) {
	Object.keys(attrs).forEach(key => elem.setAttribute(key, attrs[key]));
}

// display quantityProductsDescription in cart
function displayQuantityProductsDescription(ajaxResult) {
	let quantityProductsDescription = document.getElementsByClassName('quantity-products-description')

	if (quantityProductsDescription.length > 0) {
		Array.from(quantityProductsDescription).forEach(element => { element.innerHTML = ajaxResult });
	}
}

// display totalProducts in cart
function displayTotalProductsInCart(ajaxResult) {
	let totalProducts = document.getElementsByClassName('shopping-cart-items')
	Array.from(totalProducts).forEach(element => { element.innerHTML = ajaxResult });
}

// display totalDiscount in cart
function displayTotalDiscount(ajaxResult) {
	let totalDiscount = document.getElementsByClassName('total-discount-cart')

	if (totalDiscount.length > 0) {
		Array.from(totalDiscount).forEach(element => { element.innerHTML = ajaxResult });
	}
}

// display subTotal in cart
function displaySubTotal(ajaxResult) {
	let subTotal = document.getElementsByClassName('sub-total-cart')

	if (subTotal.length > 0) {
		Array.from(subTotal).forEach(element => { element.innerHTML = ajaxResult });
	}
}

// display total in cart
function displayTotal(ajaxResult) {
	let total = document.getElementsByClassName('total-cart')
	Array.from(total).forEach(element => { element.innerHTML = ajaxResult });
}

// display total in cart
function displayTotalWithChilexpress(ajaxResult) {
	let total = document.getElementsByClassName('totalCartWithChilexpress')
	Array.from(total).forEach(element => { element.innerHTML = ajaxResult });
}

// display empty cart HTML content
function displayEmptyCart() {
	let contentWithProducts = document.getElementsByClassName('content-with-products')

	Array.from(contentWithProducts).forEach(element => {
		if (element.classList.contains('d-flex')) { // class is from: modal_shopping_cart
			element.classList.replace('d-flex', 'd-none')
		} else {
			element.classList.replace('d-flex', 'd-none')
		}
	});

	let contentNoProducts = document.getElementsByClassName('content-no-products')
	let contentNoProductsCartIndex = document.getElementsByClassName('content-no-products-cart-index')

	Array.from(contentNoProducts).forEach(element => { element.classList.replace('d-none', 'd-flex') });
	Array.from(contentNoProductsCartIndex).forEach(element => { element.classList.replace('d-none', 'd-block') });
}

// update data-attributes packages for send in calculateDeliveryChilexpress()
function updateDataForPackageChilexpress(totalWeight) {
	const dataPackageChilexpress = document.getElementById('dataPackageChilexpress')
	if (dataPackageChilexpress) {
		dataPackageChilexpress.dataset.packageTotalWeight = totalWeight
	}
}

// remove product element
function removeProduct(id) {
	let itemProduct = document.querySelectorAll(`[data-cart-product-id="${id}"]`)
	Array.from(itemProduct).forEach(element => {
		let parent = element.closest('.item-product')
		parent.classList.add('animated', 'fadeOutRight')
		setTimeout(() => { parent.remove() }, 500);
	});

}

// Revisa si total_a_pagar coincide con sub_total + costo_envio + descuento
function checkTotalSumWithChilexpress() {
	const totalCartWithChilexpress = document.getElementsByClassName('totalCartWithChilexpress')

	for (let i = 0; i < totalCartWithChilexpress.length; i++) {
		const element = totalCartWithChilexpress[i];
		if (element) {
			var subTotalCart = document.querySelector('.sub-total-cart')
			var totalDiscount = document.querySelector('.total-discount-cart')

			var amountChilexpress = document.getElementById('amountChilexpress')

			let numberAmountChilexpress = convertCurrencyToNumber(amountChilexpress.innerHTML)
			let numberSubTotalCart = convertCurrencyToNumber(subTotalCart.innerHTML)
			let numberTotalCartWithChilexpress = convertCurrencyToNumber(element.innerHTML)

			if (totalDiscount) {
				// delete string characters in one line (ej: –$9.500)
				let numberTotalDiscount = Number(totalDiscount.innerHTML.replace(/[$.–]/g, ''))
				var totalToPay = (numberAmountChilexpress + numberSubTotalCart) - numberTotalDiscount
			} else {
				var totalToPay = (numberAmountChilexpress + numberSubTotalCart)
			}

			if (numberTotalCartWithChilexpress !== totalToPay) {
				element.innerHTML = currencyNumber(totalToPay)
			}
		}
	}
}

// Load more products on scroll down (infinite scroll)

function productsAreNotFiltered() {
	let filterParent = document.getElementById('filterParent')

	if (filterParent) {
		let inputs = filterParent.querySelectorAll('input[type="checkbox"]')
		var inputsChecked = []

		for (let i = 0; i < inputs.length; i++) {
			const input = inputs[i];

			if (input.checked) {
				inputsChecked.push(input)
			}
		}

		return inputsChecked.length == 0 // true or false
	}
}


function ajaxLoadMoreProducts() {
	let loadingIconProducts = document.getElementById('loadingProducts')
	let products = document.getElementsByClassName('product-sku')
	let products_ids = []

	for (let i = 0; i < products.length; i++) {
		const product = products[i];
		let id = product.dataset.productId
		products_ids.push(id)
	}

	$.ajax({
		url: '/load_more_products',
		type: 'get',
		dataType: 'script',
		data: {
			ids: products_ids
		},
		success: function (result) {
			loadingIconProducts.classList.add('d-none')

			// vienen resultados ? true : false (undefined)
			return (result == undefined)
		}
	});
}

// Hice window con estas funciones ya que las utilizo tanto en archivos js como en js.erb

window.scrollForLoadMoreProducts = function () {
	let canExecuteNewAjaxRequest = true
	let loadingIconProducts = document.getElementById('loadingProducts')
	const productContent = document.getElementById('product-content')

	// scroll event
	window.addEventListener('scroll', function () {
		if (canExecuteNewAjaxRequest && productsAreNotFiltered() && window.scrollY >= productContent.clientHeight - 600) {

			canExecuteNewAjaxRequest = ajaxLoadMoreProducts()

			loadingIconProducts.classList.remove('d-none') // mejorar, intentar que no aparezca si AJAX no retorna nada
		}
	});
}


// updatePrices in products async in js.erb files and also in differents views as carts/index
window.updatePriceProduct = function (event) {
	loadingGif('d-none', 'd-flex')

	let params = `id=${event.dataset.cartProductId}&quantity=${event.value}`
	let url = `/update_price?${params}` // cart_products/update_price

	fetch(url, {
		method: 'PUT'
	})
		.then(function (response) {
			if (response.ok) {
				return response.json();
			} else {
				$('#modalForErrors').modal('show')
				loadingGif('d-flex', 'd-none')
			}
		})
		.then(function (result) {
			if (result) {
				let priceByProduct = document.querySelectorAll(`[data-cart-product-id="${event.dataset.cartProductId}"]`)
				Array.from(priceByProduct).forEach(element => {
					if (element.classList.contains('price-by-quantity')) { //update all the prices in that product with that data-attribute
						element.innerHTML = result.price
					} else if (element.classList.contains('changeQuantityProducts')) { // update the option in all the select with that data-attribute
						element.options.selectedIndex = event.value - 1 // por alguna razon el valor del select aumenta en 1, se le resta - 1 para que muestre el valor que seleccionó
					} else if (element.classList.contains('quantity-my-order')) { // update quantity in checkout form
						element.innerHTML = ` x ${event.value}`
					}
				});

				// Update async innerHTML with the JSON result for each class
				displayQuantityProductsDescription(result.quantity_product_description)
				displayTotalProductsInCart(result.quantity_product_description.split(" ")[0])

				displayTotalDiscount(result.total_discount)
				displaySubTotal(result.sub_total)
				displayTotal(result.total)
				displayTotalWithChilexpress(result.total_with_chilexpress)

				loadingGif('d-flex', 'd-none')
			}
		})
}

// global function for deleteCartProduct in js.erb and views
window.deleteCartProduct = function () {
	const linkDeleteProduct = document.getElementsByClassName('delete-cart-product')

	for (let i = 0; i < linkDeleteProduct.length; i++) {
		const element = linkDeleteProduct[i];

		element.addEventListener("click", function (e) {
			this.disabled = true
			this.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`
			deleteProduct(e)
		}, false)
	}
}

// deleteProducts async in js.erb files and also in differents views as carts/index
window.deleteProduct = function (event) {
	event.preventDefault();
	let id = event.target.dataset.cartProductId
	let url = `/cart_products/${id}`

	fetch(url, {
		method: 'DELETE'
	})
		.then(function (response) {
			return response.json();
		})
		.then(function (result) {
			removeProduct(id)

			displayQuantityProductsDescription(result.quantity_product_description)

			let quantityElements = result.quantity_product_description.split(" ")[0]
			displayTotalProductsInCart(quantityElements)

			if (quantityElements > 0) {
				displayTotalDiscount(result.total_discount)
				displaySubTotal(result.sub_total)
				displayTotal(result.total)
				displayTotalWithChilexpress(result.total_with_chilexpress)

			} else { //show empty cart HTML
				displayEmptyCart()
			}

		})
}

// Products index: change input-radio color for product 
window.changeInputRadioAttributesProduct = function () {
	const inputRadioAttributeProductIndex = document.getElementsByClassName('inputRadioAttributeProductIndex')

	if (inputRadioAttributeProductIndex) {
		for (let i = 0; i < inputRadioAttributeProductIndex.length; i++) {
			const inputRadio = inputRadioAttributeProductIndex[i];

			inputRadio.addEventListener('change', function (event) {
				var divParentProductSku = event.target.closest('.card-products').getElementsByClassName('product-sku')

				for (let j = 0; j < divParentProductSku.length; j++) {
					var divParent = divParentProductSku[j];
					var cardFooter = divParent.nextElementSibling
					var img = divParent.getElementsByTagName('img')[0]
					var currentCheckBox = document.getElementById(`${event.target.dataset.productId}_${event.target.value}`)
					var divAnimatedClass = divParent.closest('.zoom-card')
					divAnimatedClass.classList.remove('animated', 'fadeIn')

					if (divParent.dataset.productId == event.target.dataset.productId) {
						divParent.classList.remove('d-none')
						cardFooter.classList.replace('d-none', 'd-block')
						img.classList.add('animated', 'fadeIn')
						currentCheckBox.checked = true
					} else {
						divParent.classList.add('d-none')
						cardFooter.classList.replace('d-block', 'd-none')
					}
				}
			}, false)
		}
	}

}

// reset address_street && address_street_number inputs
window.resetAddressAndNumberStreet = function (street = 'address_street', streetNumber = 'address_street_number', room = 'address_street_room') {
	const addressStreet = document.getElementById(street)
	const numberStreet = document.getElementById(streetNumber)
	const addressRoom = document.getElementById(room)

	if (addressStreet.value !== '' || numberStreet.value !== '') {
		addressStreet.dataset.streetNameId = ''
		addressStreet.value = ''
		numberStreet.value = ''
		addressRoom.value = ''
	}
}

window.defaultOption = function () {
	let option = document.createElement("option")
	option.innerHTML = "Seleccione una comuna"
	option.value = ""
	return option
}

window.displayAlertWithTimeOut = function (alert, message, streetResult) {
	alert.classList.replace('d-none', 'd-block')
	alert.innerHTML = `${message} <span class="fw-500">${streetResult.toUpperCase()}</span>, verifica`

	setTimeout(() => { alert.classList.add('animated', 'fadeOut') }, 8000); // 8 seconds animated effect
	setTimeout(() => {
		alert.classList.replace('d-block', 'd-none');
		alert.classList.remove('animated', 'fadeOut')
	}, 8650); // 8.6 seconds d-none div
}

// form on submit && ajax:success
window.loadSpinnerOnAjaxFormSubmit = function () {
	const forms = document.getElementsByTagName('form')
	for (let i = 0; i < forms.length; i++) {
		const form = forms[i];
		let button = form.querySelector('button[type="submit"]') || form.querySelector('input[type="submit"]')

		// add spinner on submit
		form.addEventListener('submit', (e) => {
			button.disabled = true
			button.innerHTML = `<span class="spinner-border spinner-border-sm"></span>
													<span class="ms-2"> ${button.dataset.disableWith}</span>`
		});

		// set original text && icon
		form.addEventListener("ajax:success", (e) => {
			if (button.hasAttribute('data-icon')) {
				button.innerHTML = `<i class="${button.dataset.icon}"></i> ${button.dataset.originalText}`
			} else {
				button.innerHTML = `${button.dataset.originalText}`
			}
		});
	}
}

// temporal solution: remove .modal-backdrop class if force to click multiples times in modal
window.removeMultiplesModalBackdrop = function () {
	const divBackdrops = document.querySelectorAll('.modal-backdrop')
	const modals = document.querySelectorAll('.modal')

	for (let i = 0; i < modals.length; i++) {
		const modal = modals[i];
		const modalId = modal.id

		// detect when close inside or outside the modal
		$(`#${modalId}`).on("hidden.bs.modal", function () {
			if (divBackdrops.length > 1) {
				divBackdrops.forEach(div => { div.remove() });
			}
		});
	}
}

// temporal solution: remove data attrs for body with offCanvas -> so scroll can works
window.removeOffCanvasDataAttrs = function () {
	const body = document.getElementsByTagName('body')[0];
	body.removeAttribute("data-bs-overflow");
	body.removeAttribute("data-bs-padding-right");
}