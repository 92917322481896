document.addEventListener("turbolinks:load", () => {
	const pollForOrder = document.getElementById('pollForOrder')

	if (pollForOrder) {
		let navBarElements = document.querySelectorAll('ul.navbar-nav')
		let hamburguerButton = document.querySelector('.hamburger')
		let searchFilterButton = document.querySelector('.searchFilter')
		let shoppingCartButton = document.querySelector('.bag-icon').parentElement

		// remove navbar elements from navbar (desktop)
		navBarElements.forEach(element => {
			element.remove()
		});

		// remove navbar elements from navbar (responsive)
		hamburguerButton.remove()
		searchFilterButton.remove()
		shoppingCartButton.remove()
	}

});
