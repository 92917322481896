window.addEventListener("turbolinks:load", () => {
	const elements = {
		skeleton: document.querySelector('.skeleton')
	}

	if (elements.skeleton) {
		document.body.style.overflow = "visible";
		elements.skeleton.style.display = "none";
	}

});