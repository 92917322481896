document.addEventListener("turbolinks:load", () => {
	const favoriteAddress = document.querySelectorAll('.favoriteAddress')

	if (favoriteAddress) {
		for (let i = 0; i < favoriteAddress.length; i++) {
			const address = favoriteAddress[i]

			address.addEventListener('change', function (e) {
				for (let i = 0; i < favoriteAddress.length; i++) {
					const uncheckedAddress = favoriteAddress[i]
					const uncheckedheartIcon = uncheckedAddress.parentElement.querySelector('i')
					uncheckedheartIcon.classList.remove('checked')
					uncheckedheartIcon.classList.add('unchecked')
				}

				if (address.checked) {
					const checkedheartIcon = address.parentElement.querySelector('i')
					checkedheartIcon.classList.remove('unchecked')
					checkedheartIcon.classList.add('checked')

					const currentUserToken = address.dataset.currentUserToken

					let url = `/update_favorite_address/${currentUserToken}?address_id=${address.value}`
					fetch(url, {
						method: 'PATCH'
					})
						.then(function (response) {
							return response.json();
						})
						.then(function (result) {
							const toastBody = document.querySelector('.toast').querySelector('.toast-body')
							toastBody.innerHTML = `${result.street.toUpperCase()} ${result.street_number}`

							$('.toast').toast('show')
						})
				}
			}, false)

		}
	}

	const btnGoToNewAccount = document.getElementById('btnGoToNewAccount')
	const divLoginSocialMedia = document.getElementById('loginSocialMedia')
	const divFormNewAccount = document.getElementById('formNewAccount')

	if (btnGoToNewAccount) {
		btnGoToNewAccount.addEventListener('click', function (e) {
			divLoginSocialMedia.classList.replace('d-block', 'd-none')
			divFormNewAccount.classList.replace('d-none', 'd-block')
			// set params in url without reload page
			const url = new URL(window.location)
			url.searchParams.set('email', 'true')
			window.history.pushState({}, '', url)
		}, false)
	}
})