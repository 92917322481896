import 'jquery-ui-bundle';
import 'ez-plus'

document.addEventListener("turbolinks:load", () => {

	// initialize tooltips everywhere
	$("[rel='tooltip']").tooltip();


	// zoom-images
	// Siempre dejar de ultimo, ya que al recargar la misma página colisiona con turbolinks
	if ($(".product-img").length > 0) {
		$(".product-img").ezPlus({
			responsive: true,
			showLens: true,
			// responsive width images
			respond: [
				{
					range: '100-425',
					zoomWindowHeight: 300,
					zoomWindowWidth: 150
				}
			],
			// effects zoom
			zoomWindowFadeIn: 400,
			zoomWindowFadeOut: 400,
			lensFadeIn: 400,
			lensFadeOut: 400
		});
	}


	// Bootstrap tabs: mantiene el tab abierto en caso de se recargue la página

	// my_orders: muestra el tab seleccionado al clickearlo
	var hash = window.location.hash;
	if (hash) {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		$(`#tabUserOrders a[href="${hash}"]`).tab('show');
	}

	// change url hash based on tab href
	var tabButtons = document.querySelectorAll('a.tab')

	for (let i = 0; i < tabButtons.length; i++) {
		const tabButton = tabButtons[i];

		tabButton.addEventListener("click", function () {
			document.location.hash = this.hash
		})
	}
})