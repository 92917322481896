document.addEventListener("turbolinks:load", () => {

	var hamburgerButton = document.querySelector('.hamburger')
	var menu = document.getElementById(hamburgerButton.dataset.bsTarget)

	hamburgerButton.addEventListener('click', function () {
		this.classList.toggle('is-active')
		if (menu.classList.contains('show')) { // collapse menu with bootstrap css class
			$(menu).collapse('hide')
		} else {
			$(menu).collapse('show')
		}
	}, false);

})