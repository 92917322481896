// validate phone
window.checkPhone = function (phone) {

	if (phone.value.length != 9) {
		phone.setCustomValidity("Telefono debe tener 9 dígitos, ej: 912345678");
		return false;
	} else {
		phone.setCustomValidity('')
		return true;
	}

}