import * as PusherPushNotifications from "@pusher/push-notifications-web"

// https://blog.pusher.com/releasing-beams-web-notifications-v1-0/
// Beams is not supported in Safari browser, add if condition to handle exception
if ('serviceWorker' in navigator && 'PushManager' in window) {
	const beamsClient = new PusherPushNotifications.Client({
		instanceId: '52bb5515-d1c8-43ce-8a86-278384d6e4a3',
	});

	beamsClient.start()
		.then(() => beamsClient.addDeviceInterest('tiendafitness'))
		.then(() => console.log('Successfully registered and subscribed!'))
		.catch(console.error);
} else {
	console.log('Pusher Client not supported for this browser')
}