import * as global from './functions.js'

document.addEventListener("turbolinks:load", () => {

	// collapse filter in responsive
	const collapseFilterProductsIndex = document.getElementById('filtersProductsIndex')
	const queryMediaMobile = window.matchMedia("(max-width: 767px)").matches

	if (collapseFilterProductsIndex && queryMediaMobile) {
		collapseFilterProductsIndex.classList.remove('show')
	}

	// filter products with AJAX when some of then change
	const elementsFilterBy = document.querySelectorAll('.filter-by')

	for (let i = 0; i < elementsFilterBy.length; i++) {
		const element = elementsFilterBy[i];
		element.addEventListener("change", filterElements, false);
	}

	function filterElements(event) {
		global.loadingGif('d-none', 'd-flex')
		const queryMediaForDesktop = window.matchMedia("(min-width: 768px)").matches

		const orderByProducts = document.getElementById('orderByProducts')
		let orderByRecommended = orderByProducts.value == 'recommended_desc' ? 'desc' : ''
		let orderByPrice = orderByProducts.value !== 'recommended_desc' ? orderByProducts.value : ''
		let brands_ids = []
		let brands = document.getElementsByName('search[by_brand]')
		let categories_ids = []
		let categories = document.getElementsByName('search[by_category]')

		for (let i = 0; i < brands.length; i++) {
			const brandCheckBox = brands[i];
			if (brandCheckBox.checked) {
				brands_ids.push(brandCheckBox.value)
			}
		}

		for (let i = 0; i < categories.length; i++) {
			const categoryCheckBox = categories[i];
			if (categoryCheckBox.checked) {
				categories_ids.push(categoryCheckBox.value)
			}
		}

		$.ajax({
			url: '/productos',
			type: 'get',
			async: true,
			data: {
				most_recommended: orderByRecommended,
				by_price: orderByPrice,
				by_brand: brands_ids,
				by_category: categories_ids
			},
			dataType: 'script',
			success: function (response) {
				global.loadingGif('d-flex', 'd-none')

				// change url with params
				window.history.pushState({ turbolinks: {} }, "", this.url);

				// scroll to top
				if (queryMediaForDesktop) {
					window.scrollTo({ top: 0, behavior: 'smooth' })
				}

				// display or hide resetFilter
				if (brands_ids.length > 0 || categories_ids.length > 0) {
					displayResetFilter()
				} else {
					displayResetFilter(false)
				}
			},
			error: function () {
				console.log("error with ajax filterElements")
			}
		});
	}

	if (elementsFilterBy) {
		function checkedInputBasedOnURLParams() {
			let params = location.search.substr(1).split('&')
			let results = {}

			// get values from params
			for (var i = 0; i < params.length; i++) {
				let temp = params[i].split('=')
				let key = decodeURIComponent(temp[0]).replace('[]', ''); // remove encoded URI characters
				let val = temp[1]

				results[key] = results[key] || [];
				results[key].push(val);
			}

			// checked input if exists in results value
			for (var key in results) {
				let values = results[key]

				let inputs = document.querySelectorAll(`input[name="search[${key}]"]`)
				for (let i = 0; i < inputs.length; i++) {
					const input = inputs[i];
					if (values.includes(input.value)) {
						input.checked = true
						displayResetFilter()
					}
				}
			}
		}
		checkedInputBasedOnURLParams()
	}

	// Reset filters on click
	function resetFilters() {
		const resetFilter = document.getElementById('resetFilters')

		if (resetFilter) {
			resetFilter.addEventListener('click', function (e) {
				for (let i = 0; i < elementsFilterBy.length; i++) {
					const input = elementsFilterBy[i];
					input.checked = false;
				}
				filterElements() // reload AJAX filter
			}, false)
		}

	}
	resetFilters()

	// Display resetFilter
	function displayResetFilter(display = true) {
		const resetFilter = document.getElementById('resetFilters')

		if (display) {
			resetFilter.classList.remove('d-none')
			resetFilter.classList.add('animated', 'fadeIn')
		} else {
			resetFilter.classList.add('d-none')
		}
	}

	// Products index: d-none || d-block cards when color-circle checkbox changes
	changeInputRadioAttributesProduct()

	// Product show: change url form when checkbox changes
	const inputsRadioAttributeProductShow = document.getElementsByClassName('inputRadioAttributeProductShow')
	const formAddProductToCart = document.querySelectorAll('.formAddProductToCart')
	const productFixedName = document.getElementById('productFixedName')
	const productFixedImg = document.getElementById('productFixedImg')

	if (inputsRadioAttributeProductShow) {
		for (let i = 0; i < inputsRadioAttributeProductShow.length; i++) {
			const inputRadio = inputsRadioAttributeProductShow[i];
			const slug = inputRadio.dataset.slug
			const newQuantityForSelect = Number(inputRadio.dataset.quantity)
			const newPrice = inputRadio.dataset.price
			const position = inputRadio.dataset.position
			const newMonthlyPayments = inputRadio.dataset.priceMonthlyPayment
			const newImage = inputRadio.dataset.image
			const typeAttribute = inputRadio.dataset.typeAttribute // size or color

			inputRadio.addEventListener('change', function () {
				// change price && monthly payments
				let currentMonthlyPayments = document.getElementById('priceThreeMonthlyPayments')
				currentMonthlyPayments.innerHTML = newMonthlyPayments

				let currentPrice = document.querySelector('.price')
				currentPrice.innerHTML = newPrice

				// change name && img for productFixed
				let newProductFixedName = `${productFixedName.innerHTML.split(" (")[0]} (${typeAttribute})`
				productFixedName.innerHTML = newProductFixedName
				productFixedImg.src = newImage

				// carousel change images
				let slideToCarousel = document.getElementById(`slideCarouselFromRadioButton-${position}`)
				slideToCarousel.click()

				for (let i = 0; i < formAddProductToCart.length; i++) {
					// get current form and change url (this because 2 forms exists, the principal and the other when scrolling down)
					const form = formAddProductToCart[i];
					form.action = `/add_product_to_cart/${slug}`

					// get current select && reset options
					const selectForProduct = form.querySelector('.selectForProduct')
					selectForProduct.options.length = 0;

					// add new options until newQuantityForSelect
					for (let i = 1; i <= newQuantityForSelect; i++) {
						let option = document.createElement("option");
						option.value = option.innerHTML = i
						selectForProduct.appendChild(option);
					}
				}
			})
		}
	}

	// Load more products on scroll
	const productContent = document.getElementById('product-content')
	if (productContent) {
		scrollForLoadMoreProducts()
	}

	// show loading spinner on click image product (index)
	const linkToProducts = document.querySelectorAll('.link-to-product')

	linkToProducts.forEach(linkToProduct => {
		linkToProduct.addEventListener('click', function (e) {
			var loadingSpinnerProduct = this.querySelector('.loading-spinner-product')
			if (loadingSpinnerProduct) {
				loadingSpinnerProduct.classList.replace('d-none', 'd-flex')
			}
		}, false)
	});
})

// disappear loadingSpinnerProduct on before-cache turbolinks
$(document).on("turbolinks:before-cache", function () {
	const loadingSpinnerProducts = document.querySelectorAll('.loading-spinner-product')
	if (loadingSpinnerProducts) {
		loadingSpinnerProducts.forEach(loadingSpinner => {
			if (loadingSpinner.classList.contains('d-flex')) {
				loadingSpinner.classList.replace('d-flex', 'd-none')
			}
		});
	}
});