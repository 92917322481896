// JS for inputs

document.addEventListener("turbolinks:load", () => {

	// change input type from password <-> text if click in eye-icon
	const inputsGroupEye = document.querySelectorAll('.input-group-eye')

	for (let i = 0; i < inputsGroupEye.length; i++) {
		const inputGroup = inputsGroupEye[i];

		inputGroup.addEventListener('click', function () {
			let inputPassword = inputGroup.parentElement.querySelector('input')
			let eyeIcon = inputGroup.querySelector('i')

			// change icon
			if (eyeIcon.classList.contains('fa-eye')) {
				eyeIcon.classList.replace('fa-eye', 'fa-eye-slash')
			} else {
				eyeIcon.classList.replace('fa-eye-slash', 'fa-eye')
			}

			// change type input
			inputPassword.type == 'password' ? inputPassword.type = 'text' : inputPassword.type = 'password'

		})
	}

});